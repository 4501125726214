<template>
  <div id="reservationContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li>
        <router-link to="/uporabniki">Uporabniki</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">{{ customersData ? 'Urejanje registracije' : 'Registracija' }} uporabnika
        za dostop do mobilne aplikacije
      </li>
    </vs-breadcrumb>

    <div id="customerEditContainer" class="relative">

      <div>

        <div class="sm:none md:flex-auto lg:flex xl:flex">

          <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
            <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

              <div class="vx-row mb-2" v-if="customersData">
                <div class="vx-col w-full">
                  <h1 v-if="customersData.user">Uredi registracijske podatke za
                    {{ !customersData.gender || customersData.gender === 'male' ? 'uporabnika' : 'uporabnico' }} <span
                      v-if="customersData.first_name && customersData.last_name">"{{ customersData.first_name }} {{ customersData.last_name }}"</span>
                  </h1>
                  <h1 v-if="!customersData.user">Registriraj
                    {{ !customersData.gender || customersData.gender === 'male' ? 'uporabnika' : 'uporabnico' }} <span
                      v-if="customersData.first_name && customersData.last_name">"{{ customersData.first_name }} {{ customersData.last_name }}</span>
                    za dostop do mobilne aplikacije</h1>
                </div>
              </div>


              <div class="vx-row mt-5">
                <div class="vx-col w-full">
                  <vs-input class="w-full" :label="'ID oznaka ' + (!customersData.gender || customersData.gender === 'male' ? 'uporabnika' : 'uporabnice')" v-model="customersData.id"
                            autocomplete="off" disabled />
                </div>
              </div>

                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <vs-input class="w-full" label="Uporabniško ime" v-model="customersData.username"
                              autocomplete="off"/>

                    <div class="flex mt-1" v-if="$v.customersData.username.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.customersData.username.required" :color="'danger'">
                          Polje "Uporabniško ime" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <vs-input class="w-full" label="Geslo" v-model="customersData.password"
                              autocomplete="off"/>

                    <div class="flex mt-1" v-if="$v.customersData.password.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.customersData.password.required" :color="'danger'">
                          Polje "Geslo" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <vs-input class="w-full" label="Ponovitev gesla" v-model="customersData.passwordRepeat"
                             autocomplete="off"/>

                    <div class="flex mt-1" v-if="$v.customersData.passwordRepeat.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.customersData.passwordRepeat.sameAsPassword" :color="'danger'">
                          Polje "Ponovitev gesla" mora vsebovati povsem enako vrednost kot polje "Geslo".
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                </div>

              <div class="flex mt-10 mb-20">
                <div class="w-1/2">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                    <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
                  </vs-col>
                </div>
                <div class="w-1/2">
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
                    <vs-button v-if="!customersData.user" size="large" color="success" icon="save" @click="registerCustomer">Registriraj
                      uporabnika
                    </vs-button>

                    <vs-button v-if="customersData.user" size="large" color="success" icon="save" @click="registerCustomer">Posodobi podatke
                      uporabnika
                    </vs-button>
                  </vs-col>
                </div>
              </div>

            </div>


          </div>
          <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3" v-if="customersData">
            <template>

            </template>
          </div>
        </div>


      </div>

    </div>


  </div>
</template>

<script>
import Vue from 'vue';
import 'flatpickr/dist/flatpickr.css';
import {required, sameAs} from "vuelidate/lib/validators";


export default {
  name: 'CustomerAsMobileAppUserRegistration',

  components: {},

  data() {
    return {
      customersData: {
        username: null,
        email: null,
        password: null,
        passwordRepeat: null,
      },
    }
  },

  validations: {
    customersData: {
      username: {
        required
      },
      password: {
        required
      },
      passwordRepeat: {
        sameAsPassword: sameAs("password")
      },
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    console.log("_this.customersData", _this.customersData);

    _this.customersData = _this.$route.params.customer ? _this.$route.params.customer : null;
    _this.customersData.username = _this.customersData.email;

    // _this.getCustomer();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.customerId = null;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    registerCustomer() {
      const _this = this;

      _this.$v.$touch();
      _this.$nextTick(() => _this.focusFirstStatus());
      if (_this.$v.$invalid) {
        console.log("this.$v.", _this.$v);
        return;
      }

      const registrationData = {
        customerId: _this.customersData.id,
        email: _this.customersData.username,
        password: _this.customersData.password
      }

      _this.$vs.loading();

      Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'customers/user', registrationData)
        .then((res) => {

          console.log("res", res);
          _this.$vs.loading.close();
          _this.$router.push({name: 'Uporabniki'});

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });


    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert').getBoundingClientRect();
      window.scrollTo(
        domRect.left + document.documentElement.scrollLeft,
        domRect.top + document.documentElement.scrollTop - 200, 'smooth'
      );
    },

    // getCustomer() {
    //   const _this = this;
    //
    //   _this.$vs.loading();
    // },

  }
}
</script>

